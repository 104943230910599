// consumer_trains_order_applyForCancel 申请取消订单
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.13:8765/doc.html#/haolv-consumer/trains-order-controller/applyForCancelUsingPOST
const consumer_trains_order_applyForCancel = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/applyForCancel',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_order_applyForCancel;